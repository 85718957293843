import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUserInfo } from "../api/query/useUserInfo";
import { useAuth } from "../ContextAndHooks/AuthContext";
import { useSettingContext } from "../ContextAndHooks/SettingContext";
import { PiMoneyFill } from "react-icons/pi";
import { IoMenuSharp } from "react-icons/io5";
import { IoMdVolumeMute } from "react-icons/io";
import { MdFlightTakeoff } from "react-icons/md";
// import { PiSignOutBold } from "react-icons/pi";

export default function Sidebar() {
  const { dispatch } = useSettingContext();
  const [sound, setSound] = useState(true);
  const handleSound = () => {
    dispatch({ type: "sound", payload: !sound });
    setSound(!sound);
  };
  const { setUser, user, setBank, setGateWayKey } = useAuth();

  const { userData, error, isLoading } = useUserInfo();
  const { setIsLogin } = useAuth();
  useEffect(() => {
    if (userData && userData?.user && userData.status === true) {
      setUser(userData.user);
      setBank(userData.bank);
      setGateWayKey(userData.key);
    }
  }, [userData]);
  function logout() {
    setIsLogin(null);
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiry");
    setUser(null);
  }
  return (
   <></>
  );
}
