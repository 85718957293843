import React, { useState } from "react";
import { useAuth } from "./ContextAndHooks/AuthContext";
import { useSettingContext } from "./ContextAndHooks/SettingContext";
import HowToPlay from "./Pages/HowToPlay";
import { Link, useNavigate } from "react-router-dom";
import { GoQuestion } from "react-icons/go";
import { VscUnmute } from "react-icons/vsc";
import { BiVolumeMute } from "react-icons/bi";

const HeaderBottom = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { dispatch } = useSettingContext();
  const [sound, setSound] = useState(true);
  const handleSound = () => {
    dispatch({ type: "sound", payload: !sound });
    setSound(!sound);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleHowToPlayClick = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <div className="header-bottom">
      <Link to="/">
        <div className="header-left">
          <img src="images/logo.svg" alt="Logo" className="logo" />
        </div>
      </Link>
      <div className="header-right d-flex align-items-center">
        <button
          style={{
            background: "#DC3545",
            color: "white",
            border: "transparent",
          }}
          onClick={handleHowToPlayClick}
          className="btn btn-warning m-font-0 rounded-pill py-1 px-2 f-14 d-flex align-items-center h-26"
        >
          <span className="material-symbols-outlined f-18 me-1">
            {/* <GoQuestion size={17} /> */}
            help
          </span>{" "}
          How to Play
        </button>
        {isModalOpen && <HowToPlay onClose={() => setIsModalOpen(false)} />}
        <div style={{ background: "#DC3545" }} className="wallet-balance h-26">
          <span style={{ color: "white" }} id="wallet_balance">
            ₹{user?.money}
           
          </span>
        </div>
        <div  className="" style={{margin: "0 10px"}}>
    
        
              { sound?
              <>
                <span className="material-symbols-outlined ico" onClick={handleSound}>
                  <VscUnmute color="red" />
                  </span>
                  </>
                  :
                  <>
                  <span className="material-symbols-outlined ico" onClick={handleSound}>
                  <BiVolumeMute color="red"/>
                  </span>
                  </>
                  }    
                
              <div>
              <input style={{display:"none"}}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="sound"
                    checked={sound}
                    onChange={handleSound}
                  />
              </div>
         
        </div>
        {/* <div className="wallet-balance h-26">
          <span id="wallet_balance">
            ₹{user?.bonusMoney}W
            
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default HeaderBottom;
