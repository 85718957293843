import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Pages/SidebarModal";
import { useAuth } from "./ContextAndHooks/AuthContext";
import { IoArrowBackSharp } from "react-icons/io5";
// import InstallButton from "./InstallButton";
const HeaderTop = () => {
  const { isLogin } = useAuth();

  return (
    <Sidebar />
    // <div className="header-top">
    //   <div className="header-left">
    //     <div
    //       to="https://rkwinzo.com/"
    //       style={{
    //         color: "white",
    //         display: "flex",
    //         alignItems: "center",
    //         gap: "4px",
    //         fontSize: "18px",
    //       }}
    //     >
          
    //       <IoArrowBackSharp />
    //       Back
    //     </div>
    //   </div>
      /* {!isLogin && (
        <div className="header-right d-flex align-items-center">
          <NavLink
            to="/auth/register"
            className="register-btn rounded-pill d-flex align-items-center me-2 reg_btn"
          >
            Register
          </NavLink>
          <NavLink
            className="login-btn rounded-pill d-flex align-items-center me-2"
            id="login"
            to="/auth/Login"
          >
            Login
          </NavLink>
        </div>
      )} */
    //   {isLogin && (
    //     <div className="header-right d-flex align-items-center">
    //       <Sidebar />
    //     </div>
    //   )}
    // </div>
  );
};

export default HeaderTop;
